<template>
  <!-- TODO: локализовать -->
  <div>
    <h1>Как не стать жертвой мошенников</h1>

    <ul class="list-unstyled warning-list">
      <li class="flex-wrap warning-item">
        <div class="flex-left">
          <span class="warning-icon">
            <i class="fa fa-credit-card"></i>
          </span>
        </div>
        <div class="flex-right">
          <p class="warning-text">
            У нас <strong>нет доставки ОМОЕ</strong> и пока мы
            <strong>не присылаем ссылок на оплату.</strong>
            <br />
            Все, кто отправляет такие ссылки - мошенники.
          </p>
        </div>
      </li>

      <li class="flex-wrap warning-item">
        <div class="flex-left">
          <span class="warning-icon">
            <i class="fa fa-comments-dollar"></i>
          </span>
        </div>
        <div class="flex-right">
          <p class="warning-text">
            <strong>Никому не сообщайте коды из СМС.</strong>
            <br />
            Мы отправляем коды в смс для подтверждения регистрации, входа в
            приложение или восстановления пароля. Если кто-то получит код, он
            может получить доступ к профилю.
          </p>
        </div>
      </li>

      <li class="flex-wrap warning-item">
        <div class="flex-left">
          <span class="warning-icon">
            <i class="fa fa-truck"></i>
          </span>
        </div>
        <div class="flex-right">
          <p class="warning-text">
            <strong>Не вносите предоплату</strong> за товар, бронь или за
            доставку.
          </p>
        </div>
      </li>

      <li class="flex-wrap warning-item">
        <div class="flex-left">
          <span class="warning-icon">
            <i class="fa fa-user-alt"></i>
          </span>
        </div>
        <div class="flex-right">
          <p class="warning-text">
            Никому <strong>не давайье свои персональные данные:</strong>
            номер паспорта, домашний адрес, данные карты, ...
          </p>
        </div>
      </li>

      <li class="flex-wrap warning-item">
        <div class="flex-left">
          <span class="warning-icon">
            <i class="fa fa-credit-card"></i>
          </span>
        </div>
        <div class="flex-right">
          <p class="warning-text">
            Покупайте товары около рыночной цены.
            <br />
            Сильно заниженная стоимость — один из признаков мошенничества.
          </p>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n";

export default {
  name: "ScammersWarning",
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
};
</script>

<style scoped>
.warning-list {
  margin: auto;
  padding: 30px;
}

.warning-item {
  justify-content: flex-start;
  background: white;
  padding: 20px;
  max-width: 700px;
  margin: auto;
  margin-bottom: 30px;
}

.warning-icon {
  font-size: 6rem;
}

.warning-text {
  padding: 10px;
  margin: auto;
}

.warning-text strong {
  font-weight: bold;
}

.flex-right {
  margin: auto;
}
</style>
